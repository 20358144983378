@import "variables";
@import "bootstrap/scss/bootstrap";

html {
  font-size: 14px;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

a {
  cursor: pointer;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

small,
.small {
  font-weight: lighter;
  font-size: 0.7em;
}

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // min-height: calc(100vh - 5rem - 1rem);

  background: $dark;
  background: radial-gradient(
    ellipse at left bottom,
    $secondary-dark-bg 0%,
    $dark 75%
  );

  .content {
    display: flex;
    flex-grow: 1;
    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}

.nav-link {
  color: inherit;
}
.py-lg-big {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
@include media-breakpoint-up(lg) {
  .py-lg-big {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
}

.landing-page,
.blog-page {
  width: 100%;
  font-size: 17px;

  h1 {
    color: #f7f7f7;
    font-size: 3rem;
    font-weight: normal;
    text-shadow: 2px 2px $primary;
  }
  h2 {
    color: #f7f7f7;
    font-size: 2.4rem;
    font-weight: normal;
    text-shadow: 2px 2px $secondary;
  }
  h3 {
    color: rgba(white, 0.75);
    font-size: 24px;
    font-weight: normal;
  }

  @include media-breakpoint-down(md) {
    h1 {
      text-shadow: 1px 1px $secondary;
    }
    h2 {
      text-shadow: 1px 1px $secondary;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 64px;
    }
    h2 {
      font-size: 48px;
    }
  }

  .section-hero {
    background: $dark;
    background: radial-gradient(
      ellipse at right top,
      $secondary-dark-bg 0%,
      $dark 75%
    );
    margin-top: -5em;
    padding-top: 5em;
  }

  .section-callout {
    background: $secondary-dark-bg;
    background: linear-gradient(120deg, $secondary-dark-bg 0%, #0b0b1e 100%);
  }

  .section-closing {
    background: $dark;
    background: radial-gradient(
      ellipse at left bottom,
      $secondary-dark-bg 0%,
      $dark 75%
    );
    margin-bottom: -5em;
    padding-bottom: 5em;
  }

  .image {
    img {
      border-radius: 2em;
      max-width: 100%;
      display: block;
    }
  }
}

.google-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .google-icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 1px;
    background-color: #fff;
    padding: 15px;

    .google-icon {
      display: block;
      width: 18px;
      height: 18px;
    }
  }
  .google-btn-text {
    flex: 1;
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.21px;
  }
  &.enabled {
    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background: #1669f2;
    }
  }

  &.disabled {
    background-color: $secondary;
    opacity: 0.6;
    cursor: default;
  }
}

.form-check,
.form-check * {
  cursor: pointer;
}
