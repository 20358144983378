// Dark Mode Color Palette for Unspam
$primary: #3366FF;
$secondary: #657D95;
$success: #2ECC71;
$info: #1A1A26;
$warning: #F39C12;
$danger: #E74C3C;
$light: #E0E0E6;
$dark: #0F0F1A;

// Backgrounds
$primary-dark-bg: $dark;
$secondary-dark-bg: rgba(8,8,129,1);

// Typography and Icons
$primary-text-color: $light;
$secondary-text-color: #B0B0B6;

// Accent Colors
$strong-accent: $primary;
$mild-accent: $secondary;

// Borders and Dividers
$border-color: #2A2A35;

// Hover and Active States
$hover-bg: #353545;
$active-text-icon: #FFFFFF;

// Error or Warning
$error-color: $danger;
$warning-color: $warning;

// Success
$success-color: $success;

// Bootstrap Overrides
$body-bg: $primary-dark-bg;
$body-color: $primary-text-color;
$border-color: $border-color;
$link-color: $strong-accent;
$link-hover-color: $mild-accent;
$btn-primary-bg: $strong-accent;
$btn-secondary-bg: $mild-accent;
$btn-danger-bg: $error-color;
$btn-warning-bg: $warning-color;
$btn-success-bg: $success-color;

// Override defaults
$font-family-sans-serif: -apple-system,"system-ui","Segoe UI",sans-serif;

$link-decoration: none;


$border-radius:         6px;                               //        .375rem !default;
$border-radius-sm:      calc($border-radius / .375 * .25); //        .25rem !default;
$border-radius-lg:      calc($border-radius / .375 * .5);  //        .5rem !default;
$border-radius-xl:      calc($border-radius / .375 * 1);   //        1rem !default;
$border-radius-2xl:     calc($border-radius / .375 * 2);   //        2rem !default;
$border-radius-pill:    calc($border-radius / .375 * 50);  //        50rem !default;

$card-bg:               rgba(255,255,255,.1);
$card-cap-bg:           rgba(255,255,255,.1);
$card-border-color:     rgba(255,255,255,.2);
$card-spacer-y:         1rem;
$card-spacer-x:         1.5rem;
$card-cap-padding-y:    $card-spacer-y * 1.5;

$h1-font-size:          2rem;
$h2-font-size:          1.15rem;
$h3-font-size:          1rem;
$h4-font-size:          0.786rem;
$headings-font-weight:  800;
h4 { text-transform: uppercase; }
